// import { Controller } from 'stimulus'
// import application from 'controllers'
//
// application.register(
//   'realms--index',
//   class extends Controller {
//
//     static values = {
//       url: String
//     }
//
//     showRealm(event) {
//       event.stopPropagation();
//
//       window.location = this.urlValue
//     }
//   }
// )
